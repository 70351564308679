import {
  ModalsActionTypes,
  SHOW_CONTACT_MODAL,
  CLOSE_CONTACT_MODAL,
  SHOW_PROJECT_MODAL,
  CLOSE_PROJECT_MODAL,
  SHOW_EXPENSE_MODAL,
  CLOSE_EXPENSE_MODAL,
  SHOW_TIME_ENTRY_EXPORT_MODAL,
  CLOSE_TIME_ENTRY_EXPORT_MODAL,
  SHOW_TIME_ENTRY_MODAL,
  CLOSE_TIME_ENTRY_MODAL,
  SHOW_SEND_LEDGER_ITEM_MODAL,
  CLOSE_SEND_LEDGER_ITEM_MODAL,
  SHOW_PAYMENT_DETAILS_MODAL,
  CLOSE_PAYMENT_DETAILS_MODAL,
  SHOW_IMPORT_PRODUCTS_MODAL,
  CLOSE_IMPORT_PRODUCTS_MODAL,
  SHOW_IMPORT_TIME_ENTRIES_MODAL,
  CLOSE_IMPORT_TIME_ENTRIES_MODAL,
  SHOW_IMPORT_EXPENSES_MODAL,
  CLOSE_IMPORT_EXPENSES_MODAL,
  SHOW_TAXES_MODAL,
  CLOSE_TAXES_MODAL,
  CLOSE_CONFIRM_MODAL,
  SHOW_CONFIRM_MODAL,
  SHOW_EXPORT_LEDGER_ITEMS_MODAL,
  CLOSE_EXPORT_LEDGER_ITEMS_MODAL,
  ContactModalOptions,
  ProjectModalOptions,
  SHOW_PRODUCT_MODAL,
  CLOSE_PRODUCT_MODAL,
  ProductModalOptions,
  SHOW_MOBILE_APP_MODAL,
  CLOSE_MOBILE_APP_MODAL,
  TimeEntryExportModalOptions,
  TimeEntryModalOptions,
  ExpenseModalOptions,
  CLOSE_FILE_MODAL,
  SHOW_FILE_MODAL,
  FileModalOptions,
  MoveFilesModalOptions,
  SHOW_MOVE_FILES_MODAL,
  CLOSE_MOVE_FILES_MODAL,
  ExportLedgerItemsModalOptions,
  SHOW_EXPORT_EXPENSES_MODAL,
  CLOSE_EXPORT_EXPENSES_MODAL,
  ExportExpensesModalOptions,
  SHOW_EXPENSE_CATEGORY_MODAL,
  CLOSE_EXPENSE_CATEGORY_MODAL,
  ExpenseCategoryModalOptions,
  SHOW_SETTINGS_EMAIL_TEMPLATE_MODAL,
  CLOSE_SETTINGS_EMAIL_TEMPLATE_MODAL,
  SettingsEmailTemplateModalOptions,
  SHOW_PROJECT_STATUS_UPDATE_MODAL,
  CLOSE_PROJECT_STATUS_UPDATE_MODAL,
  ProjectStatusUpdateModalOptions,
  ShowSendLedgerItemModalOptions,
  SHOW_EXPORT_PRODUCTS_MODAL,
  CLOSE_EXPORT_PRODUCTS_MODAL,
  ExportProductsModalOptions,
  SHOW_CUSTOM_FIELD_MODAL,
  CLOSE_CUSTOM_FIELD_MODAL,
  CustomFieldModalOptions,
  SHOW_TASK_MODAL,
  CLOSE_TASK_MODAL,
  TaskModalOptions,
  SHOW_BOARD_MODAL,
  CLOSE_BOARD_MODAL,
  BoardModalOptions,
  SHOW_CALENDAR_EVENT_MODAL,
  CLOSE_CALENDAR_EVENT_MODAL,
  CalendarEventModalOptions,
  SHOW_ATTACHMENTS_VIEWER_MODAL,
  CLOSE_ATTACHMENTS_VIEWER_MODAL,
  AttachmentsViewerOptions,
  AddAccountantModalOptions,
  SHOW_ADD_ACCOUNTANT_MODAL,
  CLOSE_ADD_ACCOUNTANT_MODAL,
  UserWorkspaceSettingModalOptions,
  SHOW_USER_WORKSPACE_SETTING_MODAL,
  CLOSE_USER_WORKSPACE_SETTING_MODAL,
  SHOW_BULK_TIME_ENTRY_MODAL,
  CLOSE_BULK_TIME_ENTRY_MODAL,
  BulkTimeEntryModalOptions,
  SHOW_CALCULATE_ADVANCE_MODAL,
  CLOSE_CALCULATE_ADVANCE_MODAL,
  CalculateAmountModalOptions,
  SHOW_SEND_CONTRACT_MODAL,
  CLOSE_SEND_CONTRACT_MODAL,
  ShowSendContractModalOptions,
  SHOW_SHARE_LINKS_MODAL,
  CLOSE_SHARE_LINKS_MODAL,
  ShowShareLinksModalOptions,
  SHOW_SEND_PROPOSAL_MODAL,
  CLOSE_SEND_PROPOSAL_MODAL,
  ShowSendProposalModalOptions,
  ShowItemsBlockSettingModalOptions,
  SHOW_ITEMS_BLOCK_SETTING_MODAL,
  CLOSE_ITEMS_BLOCK_SETTING_MODAL,
  SHOW_ITEM_BLOCK_SETTING_MODAL,
  ShowItemBlockSettingModalOptions,
  CLOSE_ITEM_BLOCK_SETTING_MODAL,
  ShowQuestionAndAnswerBlockSettingModalOptions,
  SHOW_QUESTION_AND_ANSWER_BLOCK_SETTING_MODAL,
  CLOSE_QUESTION_AND_ANSWER_BLOCK_SETTING_MODAL,
  ShowDirectionsModalOptions,
  SHOW_DIRECTIONS_MODAL,
  CLOSE_DIRECTIONS_MODAL,
  LedgerItemPaymentModalOptions,
  SHOW_LEDGER_ITEM_PAYMENT_MODAL,
  CLOSE_LEDGER_ITEM_PAYMENT_MODAL,
  SHOW_LEDGER_ITEM_TRANSACTION_HISTORY_MODAL,
  CLOSE_LEDGER_ITEM_TRANSACTION_HISTORY_MODAL,
  LedgerItemTransactionHistoryModalOptions,
  ledgerItemClaimModalOptions,
  SHOW_LEDGER_ITEM_CLAIM_MODAL,
  CLOSE_LEDGER_ITEM_CLAIM_MODAL,
  SHOW_PAYMENT_MODAL,
  CLOSE_PAYMENT_MODAL,
  PaymentModalOptions,
  SHOW_PAYMENT_INITIATION_MODAL,
  CLOSE_PAYMENT_INITIATION_MODAL,
  PaymentInitiationModalOptions,
  DealModalOptions,
  SHOW_DEAL_MODAL,
  CLOSE_DEAL_MODAL,
  SHOW_DEAL_STAGE_MODAL,
  DealStageModalOptions,
  CLOSE_DEAL_STAGE_MODAL,
  SHOW_CONTENT_BLOCK_TEMPLATE_MODAL,
  CLOSE_CONTENT_BLOCK_TEMPLATE_MODAL,
  ContentBlockTemplateModalOptions,
  SHOW_TAX_MODAL,
  CLOSE_TAX_MODAL,
  TaxModalOptions,
  WorkTypeModalOptions,
  SHOW_WORK_TYPE_MODAL,
  CLOSE_WORK_TYPE_MODAL,
  DiscountModalOptions,
  SHOW_DISCOUNT_MODAL,
  CLOSE_DISCOUNT_MODAL,
  SHOW_PEPPOL_PARTICIPANT_MODAL,
  CLOSE_PEPPOL_PARTICIPANT_MODAL,
  PeppolParticipantModalOptions,
  FinancialYearModalOptions,
  SHOW_FINANCIAL_YEAR_MODAL,
  CLOSE_FINANCIAL_YEAR_MODAL,
  TransactionModalOptions,
  SHOW_TRANSACTION_MODAL,
  CLOSE_TRANSACTION_MODAL,
  CLOSE_PLAYBOOK_SUBMISSION_MODAL,
  SHOW_PLAYBOOK_SUBMISSION_MODAL,
  ShowPlaybookSubmissionModalOptions,
  ShowBulkProjectStatusUpdateModalOptions,
  CLOSE_BULK_PROJECT_STATUS_UPDATE_MODAL,
  SHOW_BULK_PROJECT_STATUS_UPDATE_MODAL,
  SHOW_SIGNATURE_MODAL,
  CLOSE_SIGNATURE_MODAL,
  ShowSignatureModalOptions,
  CLOSE_EMAIL_VIEWER_MODAL,
  SHOW_EMAIL_VIEWER_MODAL,
  EmailViewerModalOptions,
  SHOW_CONTACT_GROUP_MODAL,
  CLOSE_CONTACT_GROUP_MODAL,
  ContactGroupModalOptions,
  SHOW_BULK_CONTACT_GROUP_MODAL,
  CLOSE_BULK_CONTACT_GROUP_MODAL,
  BulkContactGroupModalOptions,
  ExportContactsModalOptions,
  SHOW_EXPORT_CONTACTS_MODAL,
  CLOSE_EXPORT_CONTACTS_MODAL,
  SHOW_CONTACT_TYPE_MODAL,
  CLOSE_CONTACT_TYPE_MODAL,
  ContactTypeModalOptions,
  SHOW_FILE_UPLOAD_MODAL,
  CLOSE_FILE_UPLOAD_MODAL,
  FileUploadModalOptions,
  SHOW_PAYMENT_QR_MODAL,
  CLOSE_PAYMENT_QR_MODAL,
  PaymentQRModalOptions,
  SHOW_CUSTOM_COMPONENT_MODAL,
  CLOSE_CUSTOM_COMPONENT_MODAL,
  CustomComponentModalOptions,
  SHOW_DOCUMENT_MODAL,
  DocumentModalOptions,
  CLOSE_DOCUMENT_MODAL,
  SHOW_DOCUMENT_TAG_MODAL,
  DocumentTagModalOptions,
  CLOSE_DOCUMENT_TAG_MODAL,
  SHOW_EXPORT_DOCUMENTS_MODAL,
  CLOSE_EXPORT_DOCUMENTS_MODAL,
  ExportDocumentsModalOptions,
  SHOW_UPLOAD_DOCUMENTS_MODAL,
  CLOSE_UPLOAD_DOCUMENTS_MODAL,
  UploadDocumentsModalOptions,
  SHOW_PRODUCT_IMPORT_MODAL,
  CLOSE_PRODUCT_IMPORT_MODAL,
  ProductImportModalOptions,
  CallModalOptions,
  SHOW_CALL_MODAL,
  CLOSE_CALL_MODAL,
  ShowSendEmailModalOptions,
  CLOSE_SEND_EMAIL_MODAL,
  SHOW_SEND_EMAIL_MODAL,
  ShowEmailTemplateModalOptions,
  SHOW_EMAIL_TEMPLATE_MODAL,
  CLOSE_EMAIL_TEMPLATE_MODAL,
  SHOW_SEQUENCE_STEP_MODAL,
  CLOSE_SEQUENCE_STEP_MODAL,
  ShowSequenceStepModalOptions,
  ShowContactEnrollmentModalOptions,
  SHOW_CONTACT_ENROLLMENT_MODAL,
  CLOSE_CONTACT_ENROLLMENT_MODAL,
} from "./types";
import { ConfirmModalAction } from "../../components/Modals/ConfirmModal";
import {
  Expense,
  GroupedTimeEntries,
  ImportedProduct,
  Workspace,
  WorkspaceTax,
} from "../../types";

export function showContactModal(
  options: ContactModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_CONTACT_MODAL,
    ...options,
  };
}

export function closeContactModal(): ModalsActionTypes {
  return {
    type: CLOSE_CONTACT_MODAL,
  };
}

export function showProjectModal(
  options: ProjectModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_PROJECT_MODAL,
    ...options,
  };
}

export function closeProjectModal(): ModalsActionTypes {
  return {
    type: CLOSE_PROJECT_MODAL,
  };
}

export function showProjectStatusUpdateModal(
  options: ProjectStatusUpdateModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_PROJECT_STATUS_UPDATE_MODAL,
    ...options,
  };
}

export function closeProjectStatusUpdateModal(): ModalsActionTypes {
  return {
    type: CLOSE_PROJECT_STATUS_UPDATE_MODAL,
  };
}

export function showExpenseModal(
  options: ExpenseModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_EXPENSE_MODAL,
    ...options,
  };
}

export function closeExpenseModal(): ModalsActionTypes {
  return {
    type: CLOSE_EXPENSE_MODAL,
  };
}

export function showTimeEntryExportModal(
  options: TimeEntryExportModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_TIME_ENTRY_EXPORT_MODAL,
    ...options,
  };
}

export function closeTimeEntryExportModal(): ModalsActionTypes {
  return {
    type: CLOSE_TIME_ENTRY_EXPORT_MODAL,
  };
}

export function showTimeEntryModal(
  options: TimeEntryModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_TIME_ENTRY_MODAL,
    ...options,
  };
}

export function closeTimeEntryModal(): ModalsActionTypes {
  return {
    type: CLOSE_TIME_ENTRY_MODAL,
  };
}

export function showSendLedgerItemModal(
  options: ShowSendLedgerItemModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_SEND_LEDGER_ITEM_MODAL,
    ...options,
  };
}

export function closeSendLedgerItemModal(): ModalsActionTypes {
  return {
    type: CLOSE_SEND_LEDGER_ITEM_MODAL,
  };
}

export function showSettingsEmailTemplateModal(
  options: SettingsEmailTemplateModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_SETTINGS_EMAIL_TEMPLATE_MODAL,
    ...options,
  };
}

export function closeSettingsEmailTemplateModal(): ModalsActionTypes {
  return {
    type: CLOSE_SETTINGS_EMAIL_TEMPLATE_MODAL,
  };
}

export function showPaymentDetailsModal(
  onSubmit?: (workspace: Workspace) => void
): ModalsActionTypes {
  return {
    type: SHOW_PAYMENT_DETAILS_MODAL,
    onSubmit: onSubmit,
  };
}

export function closePaymentDetailsModal(): ModalsActionTypes {
  return {
    type: CLOSE_PAYMENT_DETAILS_MODAL,
  };
}

export function showImportProductsModal(
  onSubmit?: (products: ImportedProduct[]) => void
): ModalsActionTypes {
  return {
    type: SHOW_IMPORT_PRODUCTS_MODAL,
    onSubmit: onSubmit,
  };
}

export function closeImportProductsModal(): ModalsActionTypes {
  return {
    type: CLOSE_IMPORT_PRODUCTS_MODAL,
  };
}

interface ImportTimeEntriesModalOptions {
  contactId?: string;
  projectId?: string;
  onSubmit?: (groupedTimeEntries: GroupedTimeEntries[]) => void;
}

export function showImportTimeEntriesModal(
  options: ImportTimeEntriesModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_IMPORT_TIME_ENTRIES_MODAL,
    ...options,
  };
}

export function closeImportTimeEntriesModal(): ModalsActionTypes {
  return {
    type: CLOSE_IMPORT_TIME_ENTRIES_MODAL,
  };
}

export function showImportExpensesModal(
  contactId?: string,
  projectId?: string,
  onSubmit?: (expenses: Expense[]) => void
): ModalsActionTypes {
  return {
    type: SHOW_IMPORT_EXPENSES_MODAL,
    contactId: contactId,
    projectId: projectId,
    onSubmit: onSubmit,
  };
}

export function closeImportExpensesModal(): ModalsActionTypes {
  return {
    type: CLOSE_IMPORT_EXPENSES_MODAL,
  };
}

export function showTaxesModal(
  onSubmit?: (taxes: WorkspaceTax[]) => void
): ModalsActionTypes {
  return {
    type: SHOW_TAXES_MODAL,
    onSubmit: onSubmit,
  };
}

export function closeTaxesModal(): ModalsActionTypes {
  return {
    type: CLOSE_TAXES_MODAL,
  };
}

export function showConfirmModal(options: {
  title: string;
  description: string | any;
  action: ConfirmModalAction;
  onConfirm: () => void;
  onCancel?: () => void;
}): ModalsActionTypes {
  return {
    type: SHOW_CONFIRM_MODAL,
    title: options.title,
    description: options.description,
    action: options.action,
    onConfirm: options.onConfirm,
    onCancel: options.onCancel,
  };
}

export function closeConfirmModal(): ModalsActionTypes {
  return {
    type: CLOSE_CONFIRM_MODAL,
  };
}

export function showProductModal(
  options: ProductModalOptions = {}
): ModalsActionTypes {
  return {
    type: SHOW_PRODUCT_MODAL,
    ...options,
  };
}

export function closeProductModal(): ModalsActionTypes {
  return {
    type: CLOSE_PRODUCT_MODAL,
  };
}

export function showMobileAppModal(): ModalsActionTypes {
  return {
    type: SHOW_MOBILE_APP_MODAL,
  };
}

export function closeMobileAppModal(): ModalsActionTypes {
  return {
    type: CLOSE_MOBILE_APP_MODAL,
  };
}

export function showFileModal(options: FileModalOptions): ModalsActionTypes {
  return {
    type: SHOW_FILE_MODAL,
    ...options,
  };
}

export function closeFileModal(): ModalsActionTypes {
  return {
    type: CLOSE_FILE_MODAL,
  };
}

export function showMoveFilesModal(
  options: MoveFilesModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_MOVE_FILES_MODAL,
    ...options,
  };
}

export function closeMoveFilesModal(): ModalsActionTypes {
  return {
    type: CLOSE_MOVE_FILES_MODAL,
  };
}

export function showExportLedgerItemsModal(
  options: ExportLedgerItemsModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_EXPORT_LEDGER_ITEMS_MODAL,
    ...options,
  };
}

export function closeExportLedgerItemsModal(): ModalsActionTypes {
  return {
    type: CLOSE_EXPORT_LEDGER_ITEMS_MODAL,
  };
}

export function showExportExpensesModal(
  options: ExportExpensesModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_EXPORT_EXPENSES_MODAL,
    ...options,
  };
}

export function closeExportExpensesModal(): ModalsActionTypes {
  return {
    type: CLOSE_EXPORT_EXPENSES_MODAL,
  };
}

export function showExpenseCategoryModal(
  options: ExpenseCategoryModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_EXPENSE_CATEGORY_MODAL,
    ...options,
  };
}

export function closeExpenseCategoryModal(): ModalsActionTypes {
  return {
    type: CLOSE_EXPENSE_CATEGORY_MODAL,
  };
}

export function showExportProductsModal(
  options: ExportProductsModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_EXPORT_PRODUCTS_MODAL,
    ...options,
  };
}

export function closeExportProductsModal(): ModalsActionTypes {
  return {
    type: CLOSE_EXPORT_PRODUCTS_MODAL,
  };
}

export function showCustomFieldModal(
  options: CustomFieldModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_CUSTOM_FIELD_MODAL,
    ...options,
  };
}

export function closeCustomFieldModal(): ModalsActionTypes {
  return {
    type: CLOSE_CUSTOM_FIELD_MODAL,
  };
}

export function showBoardModal(options: BoardModalOptions): ModalsActionTypes {
  return {
    type: SHOW_BOARD_MODAL,
    ...options,
  };
}

export function closeBoardModal(): ModalsActionTypes {
  return {
    type: CLOSE_BOARD_MODAL,
  };
}

export function showTaskModal(options: TaskModalOptions): ModalsActionTypes {
  return {
    type: SHOW_TASK_MODAL,
    ...options,
  };
}

export function closeTaskModal(): ModalsActionTypes {
  return {
    type: CLOSE_TASK_MODAL,
  };
}

export function showCalendarEventModal(
  options: CalendarEventModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_CALENDAR_EVENT_MODAL,
    ...options,
  };
}

export function closeCalendarEventModal(): ModalsActionTypes {
  return {
    type: CLOSE_CALENDAR_EVENT_MODAL,
  };
}

export function showAttachmentsViewerModal(
  options: AttachmentsViewerOptions
): ModalsActionTypes {
  return {
    type: SHOW_ATTACHMENTS_VIEWER_MODAL,
    ...options,
  };
}

export function closeAttachmentsViewerModal(): ModalsActionTypes {
  return {
    type: CLOSE_ATTACHMENTS_VIEWER_MODAL,
  };
}

export function showAddAccountantModal(
  options: AddAccountantModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_ADD_ACCOUNTANT_MODAL,
    ...options,
  };
}

export function closeAddAccountantModal(): ModalsActionTypes {
  return {
    type: CLOSE_ADD_ACCOUNTANT_MODAL,
  };
}

export function showUserWorkspaceSettingModal(
  options: UserWorkspaceSettingModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_USER_WORKSPACE_SETTING_MODAL,
    ...options,
  };
}

export function closeUserWorkspaceSettingModal(): ModalsActionTypes {
  return {
    type: CLOSE_USER_WORKSPACE_SETTING_MODAL,
  };
}

export function showBulkTimeEntryModal(
  options: BulkTimeEntryModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_BULK_TIME_ENTRY_MODAL,
    ...options,
  };
}

export function closeBulkTimeEntryModal(): ModalsActionTypes {
  return {
    type: CLOSE_BULK_TIME_ENTRY_MODAL,
  };
}

export function showCalculateAmountModal(
  options: CalculateAmountModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_CALCULATE_ADVANCE_MODAL,
    ...options,
  };
}

export function closeCalculateAmountModal(): ModalsActionTypes {
  return {
    type: CLOSE_CALCULATE_ADVANCE_MODAL,
  };
}

export function showSendContractModal(
  options: ShowSendContractModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_SEND_CONTRACT_MODAL,
    ...options,
  };
}

export function closeSendContractModal(): ModalsActionTypes {
  return {
    type: CLOSE_SEND_CONTRACT_MODAL,
  };
}

export function showShareLinksModal(
  options: ShowShareLinksModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_SHARE_LINKS_MODAL,
    ...options,
  };
}

export function closeShareLinksModal(): ModalsActionTypes {
  return {
    type: CLOSE_SHARE_LINKS_MODAL,
  };
}

export function showSendProposalModal(
  options: ShowSendProposalModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_SEND_PROPOSAL_MODAL,
    ...options,
  };
}

export function closeSendProposalModal(): ModalsActionTypes {
  return {
    type: CLOSE_SEND_PROPOSAL_MODAL,
  };
}

export function showItemsBlockSettingModal(
  options: ShowItemsBlockSettingModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_ITEMS_BLOCK_SETTING_MODAL,
    ...options,
  };
}

export function closeItemsBlockSettingModal(): ModalsActionTypes {
  return {
    type: CLOSE_ITEMS_BLOCK_SETTING_MODAL,
  };
}

export function showItemBlockSettingModal(
  options: ShowItemBlockSettingModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_ITEM_BLOCK_SETTING_MODAL,
    ...options,
  };
}

export function closeItemBlockSettingModal(): ModalsActionTypes {
  return {
    type: CLOSE_ITEM_BLOCK_SETTING_MODAL,
  };
}

export function showContentBlockTemplateModal(
  options: ContentBlockTemplateModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_CONTENT_BLOCK_TEMPLATE_MODAL,
    ...options,
  };
}

export function closeContentBlockTemplateModal(): ModalsActionTypes {
  return {
    type: CLOSE_CONTENT_BLOCK_TEMPLATE_MODAL,
  };
}

export function showDirectionsModal(
  options: ShowDirectionsModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_DIRECTIONS_MODAL,
    ...options,
  };
}

export function closeDirectionsModal(): ModalsActionTypes {
  return {
    type: CLOSE_DIRECTIONS_MODAL,
  };
}

export function showLedgerItemPaymentModal(
  options: LedgerItemPaymentModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_LEDGER_ITEM_PAYMENT_MODAL,
    ...options,
  };
}

export function closeLedgerItemPaymentModal(): ModalsActionTypes {
  return {
    type: CLOSE_LEDGER_ITEM_PAYMENT_MODAL,
  };
}

export function showLedgerItemTransactionHistoryModal(
  options: LedgerItemTransactionHistoryModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_LEDGER_ITEM_TRANSACTION_HISTORY_MODAL,
    ...options,
  };
}

export function closeLedgerItemTransactionHistoryModal(): ModalsActionTypes {
  return {
    type: CLOSE_LEDGER_ITEM_TRANSACTION_HISTORY_MODAL,
  };
}

export function showledgerItemClaimModal(
  options: ledgerItemClaimModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_LEDGER_ITEM_CLAIM_MODAL,
    ...options,
  };
}

export function closeLedgerItemClaimModal(): ModalsActionTypes {
  return {
    type: CLOSE_LEDGER_ITEM_CLAIM_MODAL,
  };
}

export function showPaymentModal(
  options: PaymentModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_PAYMENT_MODAL,
    ...options,
  };
}

export function closePaymentModal(): ModalsActionTypes {
  return {
    type: CLOSE_PAYMENT_MODAL,
  };
}

export function showPaymentInitiationModal(
  options: PaymentInitiationModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_PAYMENT_INITIATION_MODAL,
    ...options,
  };
}

export function closePaymentInitiationModal(): ModalsActionTypes {
  return {
    type: CLOSE_PAYMENT_INITIATION_MODAL,
  };
}

export function showDealModal(options: DealModalOptions): ModalsActionTypes {
  return {
    type: SHOW_DEAL_MODAL,
    ...options,
  };
}

export function closeDealModal(): ModalsActionTypes {
  return {
    type: CLOSE_DEAL_MODAL,
  };
}

export function showDealStageModal(
  options: DealStageModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_DEAL_STAGE_MODAL,
    ...options,
  };
}

export function closeDealStageModal(): ModalsActionTypes {
  return {
    type: CLOSE_DEAL_STAGE_MODAL,
  };
}

export function showTaxModal(options: TaxModalOptions): ModalsActionTypes {
  return {
    type: SHOW_TAX_MODAL,
    ...options,
  };
}

export function closeTaxModal(): ModalsActionTypes {
  return {
    type: CLOSE_TAX_MODAL,
  };
}

export function showWorkTypeModal(
  options: WorkTypeModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_WORK_TYPE_MODAL,
    ...options,
  };
}

export function closeWorkTypeModal(): ModalsActionTypes {
  return {
    type: CLOSE_WORK_TYPE_MODAL,
  };
}

export function showDiscountModal(
  options: DiscountModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_DISCOUNT_MODAL,
    ...options,
  };
}

export function closeDiscountModal(): ModalsActionTypes {
  return {
    type: CLOSE_DISCOUNT_MODAL,
  };
}

export function showPeppolParticipantModal(
  options: PeppolParticipantModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_PEPPOL_PARTICIPANT_MODAL,
    ...options,
  };
}

export function closePeppolParticipantModal(): ModalsActionTypes {
  return {
    type: CLOSE_PEPPOL_PARTICIPANT_MODAL,
  };
}

export function showFinancialYearModal(
  options: FinancialYearModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_FINANCIAL_YEAR_MODAL,
    ...options,
  };
}

export function closeFinancialYearModal(): ModalsActionTypes {
  return {
    type: CLOSE_FINANCIAL_YEAR_MODAL,
  };
}

export function showTransactionModal(
  options: TransactionModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_TRANSACTION_MODAL,
    ...options,
  };
}

export function closeTransactionModal(): ModalsActionTypes {
  return {
    type: CLOSE_TRANSACTION_MODAL,
  };
}

export function showQuestionAndAnswerBlockSettingModal(
  options: ShowQuestionAndAnswerBlockSettingModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_QUESTION_AND_ANSWER_BLOCK_SETTING_MODAL,
    ...options,
  };
}

export function closeQuestionAndAnswerBlockSettingModal(): ModalsActionTypes {
  return {
    type: CLOSE_QUESTION_AND_ANSWER_BLOCK_SETTING_MODAL,
  };
}

export function showPlaybookSubmissionModal(
  options: ShowPlaybookSubmissionModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_PLAYBOOK_SUBMISSION_MODAL,
    ...options,
  };
}

export function closePlaybookSubmissionModal(): ModalsActionTypes {
  return {
    type: CLOSE_PLAYBOOK_SUBMISSION_MODAL,
  };
}

export function showBulkProjectStatusUpdateModal(
  options: ShowBulkProjectStatusUpdateModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_BULK_PROJECT_STATUS_UPDATE_MODAL,
    ...options,
  };
}

export function closeBulkProjectStatusUpdateModal(): ModalsActionTypes {
  return {
    type: CLOSE_BULK_PROJECT_STATUS_UPDATE_MODAL,
  };
}

export function showSignatureModal(
  options: ShowSignatureModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_SIGNATURE_MODAL,
    ...options,
  };
}

export function closeSignatureModal(): ModalsActionTypes {
  return {
    type: CLOSE_SIGNATURE_MODAL,
  };
}

export function showEmailViewerModal(
  options: EmailViewerModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_EMAIL_VIEWER_MODAL,
    ...options,
  };
}

export function closeEmailViewerModal(): ModalsActionTypes {
  return {
    type: CLOSE_EMAIL_VIEWER_MODAL,
  };
}

export function showContactGroupModal(
  options: ContactGroupModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_CONTACT_GROUP_MODAL,
    ...options,
  };
}

export function closeContactGroupModal(): ModalsActionTypes {
  return {
    type: CLOSE_CONTACT_GROUP_MODAL,
  };
}

export function showBulkContactGroupModal(
  options: BulkContactGroupModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_BULK_CONTACT_GROUP_MODAL,
    ...options,
  };
}

export function closeBulkContactGroupModal(): ModalsActionTypes {
  return {
    type: CLOSE_BULK_CONTACT_GROUP_MODAL,
  };
}

export function showExportContactsModal(
  options: ExportContactsModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_EXPORT_CONTACTS_MODAL,
    ...options,
  };
}

export function closeExportContactsModal(): ModalsActionTypes {
  return {
    type: CLOSE_EXPORT_CONTACTS_MODAL,
  };
}

export function showContactTypeModal(
  options: ContactTypeModalOptions
): ModalsActionTypes {
  return { type: SHOW_CONTACT_TYPE_MODAL, ...options };
}

export function closeContactTypeModal(): ModalsActionTypes {
  return { type: CLOSE_CONTACT_TYPE_MODAL };
}

export function showFileUploadModal(
  options: FileUploadModalOptions
): ModalsActionTypes {
  return { type: SHOW_FILE_UPLOAD_MODAL, ...options };
}

export function closeFileUploadModal(): ModalsActionTypes {
  return { type: CLOSE_FILE_UPLOAD_MODAL };
}

export function showPaymentQRModal(
  options: PaymentQRModalOptions
): ModalsActionTypes {
  return { type: SHOW_PAYMENT_QR_MODAL, ...options };
}

export function closePaymentQRModal(): ModalsActionTypes {
  return { type: CLOSE_PAYMENT_QR_MODAL };
}

export function showCustomComponentModal(
  options: CustomComponentModalOptions
): ModalsActionTypes {
  return { type: SHOW_CUSTOM_COMPONENT_MODAL, ...options };
}

export function closeCustomComponentModal(): ModalsActionTypes {
  return { type: CLOSE_CUSTOM_COMPONENT_MODAL };
}

export function showDocumentModal(
  options: DocumentModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_DOCUMENT_MODAL,
    ...options,
  };
}

export function closeDocumentModal(): ModalsActionTypes {
  return {
    type: CLOSE_DOCUMENT_MODAL,
  };
}

export function showDocumentTagModal(
  options: DocumentTagModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_DOCUMENT_TAG_MODAL,
    ...options,
  };
}

export function closeDocumentTagModal(): ModalsActionTypes {
  return {
    type: CLOSE_DOCUMENT_TAG_MODAL,
  };
}

export function showExportDocumentsModal(
  options: ExportDocumentsModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_EXPORT_DOCUMENTS_MODAL,
    ...options,
  };
}

export function closeExportDocumentsModal(): ModalsActionTypes {
  return {
    type: CLOSE_EXPORT_DOCUMENTS_MODAL,
  };
}

export function showUploadDocumentsModal(
  options: UploadDocumentsModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_UPLOAD_DOCUMENTS_MODAL,
    ...options,
  };
}

export function closeUploadDocumentsModal(): ModalsActionTypes {
  return {
    type: CLOSE_UPLOAD_DOCUMENTS_MODAL,
  };
}

export function showProductImportModal(
  options: ProductImportModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_PRODUCT_IMPORT_MODAL,
    ...options,
  };
}

export function closeProductImportModal(): ModalsActionTypes {
  return {
    type: CLOSE_PRODUCT_IMPORT_MODAL,
  };
}

export function showCallModal(options: CallModalOptions): ModalsActionTypes {
  return {
    type: SHOW_CALL_MODAL,
    ...options,
  };
}

export function closeCallModal(): ModalsActionTypes {
  return {
    type: CLOSE_CALL_MODAL,
  };
}

export function showSendEmailModal(
  options: ShowSendEmailModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_SEND_EMAIL_MODAL,
    ...options,
  };
}

export function closeSendEmailModal(): ModalsActionTypes {
  return {
    type: CLOSE_SEND_EMAIL_MODAL,
  };
}

export function showEmailTemplateModal(
  options: ShowEmailTemplateModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_EMAIL_TEMPLATE_MODAL,
    ...options,
  };
}

export function closeEmailTemplateModal(): ModalsActionTypes {
  return {
    type: CLOSE_EMAIL_TEMPLATE_MODAL,
  };
}

export function showSequenceStepModal(
  options: ShowSequenceStepModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_SEQUENCE_STEP_MODAL,
    ...options,
  };
}

export function closeSequenceStepModal(): ModalsActionTypes {
  return {
    type: CLOSE_SEQUENCE_STEP_MODAL,
  };
}

export function showContactEnrollmentModal(
  options: ShowContactEnrollmentModalOptions
): ModalsActionTypes {
  return {
    type: SHOW_CONTACT_ENROLLMENT_MODAL,
    ...options,
  };
}

export function closeContactEnrollmentModal(): ModalsActionTypes {
  return {
    type: CLOSE_CONTACT_ENROLLMENT_MODAL,
  };
}
